import React, { useMemo } from "react";
import styled from "styled-components";
import { EABlockContent } from "../../components/BlockContent";
import { ArticleTiles } from "../Articles/ArticleTiles";
import { ShareLinks } from "../ShareLinks";
import { SectionHeadingNav } from "../PageSection/SectionHeadingNav";
import toArticle from "../../util/toArticle";

const BlogPostBody = styled.div`
    display: flex;
    width: 100%;

    ${(p) => p.theme.media("xl")} {
        display: grid;
        grid-template-columns: 66.666666666% calc(33.333333337% - 60px);
        grid-template-areas: "content sidebar";
        gap: 60px;
    }
`;

const ContentWrapper = styled.div`
    grid-area: content;
    width: 100%;
`;

const ShareLinkBodyWrapper = styled.div`
    display: flex;
    position: sticky;
    top: ${(p) => p.theme.height.navBar};

    justify-content: center;
    background: ${(p) => p.theme.color.background.default};
    padding: 32px;

    z-index: 1;

    ${(p) => p.theme.media("xl")} {
        display: none;
    }
`;

const SidebarWrapper = styled.div`
    display: none;
    grid-area: sidebar;
    height: 100%;
    flex-direction: column;

    ${(p) => p.theme.media("xl")} {
        display: flex;
    }
`;

const SidebarTopWrapper = styled.div`
    width: 100%;
    flex-grow: 1;
`;

const SidebarTop = styled.div`
    position: sticky;
    top: calc(${(p) => p.theme.height.navBar} + 32px);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
`;

const RelatedArticlesWrapper = styled.div`
    padding-top: 64px;
`;

export const BlogBody = ({
    blogPost,
}: {
    blogPost: GatsbyTypes.SanityBlogPost;
}) => {
    const { _rawBody, title, relatedArticles } = blogPost;

    const relatedPosts = useMemo(
        () =>
            relatedArticles?.relatedArticlesArray?.length > 0
                ? relatedArticles.relatedArticlesArray.map(toArticle)
                : [],
        [relatedArticles]
    );

    return (
        <BlogPostBody>
            <ContentWrapper>
                <ShareLinkBodyWrapper>
                    <ShareLinks />
                </ShareLinkBodyWrapper>
                <EABlockContent blocks={_rawBody} />
            </ContentWrapper>
            <SidebarWrapper>
                <SidebarTopWrapper>
                    <SidebarTop>
                        <ShareLinks />
                        <SectionHeadingNav blocks={_rawBody} title={title} />
                    </SidebarTop>
                </SidebarTopWrapper>
                <RelatedArticlesWrapper>
                    <ArticleTiles articles={relatedPosts} layout="sidebar" />
                </RelatedArticlesWrapper>
            </SidebarWrapper>
        </BlogPostBody>
    );
};
