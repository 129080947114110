import { GatsbyImage } from "gatsby-plugin-image";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Topic } from "../Articles/components";
import { Heading } from "../Typography/Heading";
import { Hyperlink } from "../Link/Hyperlink";
import { getDateFromSanityFormat } from "../HeadSEO";

const Header = styled.header`
    width: 100%;

    padding: 0px;
    display: grid;
    gap: 60px;

    grid-template-areas:
        "overview"
        "image"
        "heading"
        "date"
        "contributors";

    ${(p) => p.theme.media("xl")} {
        grid-template-columns: 66.666666666% calc(33.333333337% - 60px);
        grid-template-areas:
            "overview date"
            "heading contributors"
            "image image";
    }
`;

const Overview = styled.div`
    grid-area: overview;
    display: flex;
    flex-direction: row;

    align-items: center;
    width: 100%;

    ${Topic} {
        margin-right: auto;
    }

    ${(p) => p.theme.media("xl")} {
        gap: 48px;

        ${Topic} {
            margin-right: unset;
        }
    }

    ${(p) => p.theme.media("xxl")} {
        gap: 64px;
    }
`;

const BlogHeading = styled(Heading)`
    grid-area: heading;
`;

const PublishedDate = styled(Heading)`
    grid-area: date;
    align-self: center;
`;

const FeaturedImageWrapper = styled.div`
    grid-area: image;
    width: 100%;

    display: flex;

    align-items: center;
    justify-content: center;

    aspect-ratio: 5 / 4;
    border-radius: ${(p) => p.theme.borderRadius.l};
    overflow: hidden;

    > div {
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

    ${(p) => p.theme.media("md")} {
        aspect-ratio: 16 / 9;
    }
`;

const ContributorDetailsWrapper = styled.div`
    grid-area: contributors;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;

    ${(p) => p.theme.media("md")} {
        flex-direction: row;
    }

    ${(p) => p.theme.media("xl")} {
        flex-direction: column;
    }
`;

const AvatarImage = styled(GatsbyImage)`
    aspect-ratio: 1 / 1;
    height: 64px;
    width: 64px;
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;

    border: 2px solid ${(p) => p.theme.color.primary.main};
    border-radius: 50%;
`;

const ContributorDetails = styled(Heading)`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
`;

// prevent reflows when the reading time fills in
const ReadingTime = styled(Heading)`
    min-width: 120px;
    text-align: right;
`;

const ScrollToBlogContent = () => {
    if (typeof document !== undefined) {
        // we are observing with the large images people are finding it hard to get to the content.  This smooth scrolls into view.
        const featuredImage = document.querySelector(
            '[class*="BlogHeader__FeaturedImageWrapper"]'
        );
        if (featuredImage) {
            window.scroll({
                behavior: "smooth",
                top:
                    featuredImage.getBoundingClientRect().bottom -
                    document.body.getBoundingClientRect().top -
                    120, // save room for nav and leave a bit of image underwear
            });
        }
    }
};

export const BlogHeader = ({
    blogPost,
}: {
    blogPost: GatsbyTypes.SanityBlogPost;
}) => {
    const {
        _rawBody,
        category,
        title,
        author,
        coAuthor,
        artist,
        publishedAt,
        featuredImage,
    } = blogPost;

    const gatsbyImage = featuredImage
        ? featuredImage?.img?.asset?.gatsbyImageData
        : null;
    const imageAlt = featuredImage ? featuredImage.alt : "";

    const time = useMemo(() => {
        const wordsPerMinute = 200;

        const wordCount = _rawBody
            // Filter blocks with children
            .filter(
                (block) =>
                    block._type === "block" && Array.isArray(block.children)
            )
            // Get a list of all the children of all the blocks
            .map((block) => block.children)
            // By reducing the children into a concatenated array
            .reduce((allChildren, children) => allChildren.concat(children), [])
            // And adding them all up
            .reduce((sum, child) => sum + child.text.split(" ").length, 0);

        return Math.ceil(wordCount / wordsPerMinute);
    }, [_rawBody]);

    const featuredImageClickBehavior = featuredImage?.link
        ? {
              href: featuredImage.link, // jump to a link
          }
        : {
              onClick: () => ScrollToBlogContent(), // scroll into view
          };

    return (
        <Header>
            <Overview>
                {category?.name && <Topic>{category.name}</Topic>}
                <ReadingTime forwardedAs="span" level="h5" noMargin>
                    {time} min read
                </ReadingTime>
            </Overview>
            {gatsbyImage && (
                <Hyperlink {...featuredImageClickBehavior}>
                    <FeaturedImageWrapper className="featured-image-wrapper">
                        {/* lighthouse: use newer fetchpriority property to load faster in chrome */}
                        <GatsbyImage
                            loading="eager"
                            fetchpriority="high"
                            image={gatsbyImage}
                            alt={imageAlt}
                        />
                    </FeaturedImageWrapper>
                </Hyperlink>
            )}
            {title && (
                <BlogHeading level="h1" noMargin>
                    {title}
                </BlogHeading>
            )}
            <PublishedDate forwardedAs="span" levelStyle="h5" noMargin>
                {getDateFromSanityFormat(publishedAt).toDateString()}
            </PublishedDate>
            <ContributorDetailsWrapper>
                {author && (
                    <ContributorDetails
                        forwardedAs="div"
                        levelStyle="h5"
                        noMargin
                    >
                        <AvatarImage
                            image={author.image.asset.gatsbyImageData}
                            alt={author.image.asset.altText || author.name}
                        />
                        Written by {author.name}, {author.title}
                        {author.company && ` at ${author.company}`}
                    </ContributorDetails>
                )}
                {coAuthor && (
                    <ContributorDetails
                        forwardedAs="div"
                        levelStyle="h5"
                        noMargin
                    >
                        <AvatarImage
                            image={coAuthor.image.asset.gatsbyImageData}
                            alt={coAuthor.image.asset.altText || coAuthor.name}
                        />
                        Written by {coAuthor.name}, {coAuthor.title}
                        {coAuthor.company && ` at ${coAuthor.company}`}
                    </ContributorDetails>
                )}
                {artist && (
                    <ContributorDetails
                        forwardedAs="div"
                        levelStyle="h5"
                        noMargin
                    >
                        <AvatarImage
                            image={artist.image.asset.gatsbyImageData}
                            alt={artist.image.asset.altText || artist.name}
                        />
                        Artwork by {artist.name}, {artist.title}
                        {artist.company && ` at ${artist.company}`}
                    </ContributorDetails>
                )}
            </ContributorDetailsWrapper>
        </Header>
    );
};
