import React, { useEffect } from "react";

/** this is just here so that we only use smooth scrolling on pages we want it on. */
export const SmoothScroll = () => {
    useEffect(() => {
        if (typeof document !== undefined) {
            document.querySelector("html").classList.add("smoothScroll");

            return () => {
                document.querySelector("html").classList.remove("smoothScroll");
            };
        }
    }, []);
    return <></>;
};
