import SanityBlockContent from "@sanity/block-content-to-react";
import React from "react";
import { gridSquares } from "../../styles/grid";
import styled from "styled-components";
import { makeAnchorLinkFromBlocks } from "../../util/makeAnchorLink";
import { Heading } from "../Typography/Heading";

const ListItem = styled.li`
    display: flex;
    list-style: none;
    :not(:last-child) {
        margin-bottom: ${gridSquares(1)};
    }
`;

const ListLink = styled.span`
    color: ${(p) => p.theme.color.secondaryOne.main};
    font-weight: 700;
    text-transform: uppercase;

    :hover {
        cursor: pointer;
        color: ${(p) => p.theme.color.secondaryOne.main};
    }
`;

const Container = styled.nav`
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    // That pixel count is designed so this takes up maximum room above the CTA footer
    // without being cut off. We should have it not so hard-coded at some point, but
    // idk how to achieve that.
    max-height: calc(100vh - ${(p) => p.theme.height.navBar} - 176px);

    background: ${(p) => p.theme.color.background.dim};
    border: ${gridSquares(1)} solid ${(p) => p.theme.color.background.dim};

    border-radius: ${(p) => p.theme.borderRadius.l};
    overflow: auto;
    scrollbar-width: none;
`;

const BlockRenderer = (props) => {
    const isH2 = props.node.style.replace(/[^\d]/g, "") === "2";

    if (isH2) {
        return (
            <ListItem>
                <a href={`#${makeAnchorLinkFromBlocks(props.node.children)}`}>
                    <ListLink>{props.children}</ListLink>
                </a>
            </ListItem>
        );
    }

    return SanityBlockContent.defaultSerializers.types.block(props);
};

export const serializers = {
    types: {
        block: BlockRenderer,
    },
};

const getHeadingBlocks = (blocks) => {
    let headingBlocks = [];

    for (const block of blocks) {
        if (block.style === "h2") {
            headingBlocks.push(block);
        }

        if (block._type === "colorCard") {
            if (!Array.isArray(block.content)) {
                continue;
            }

            const h2inColorCards = block.content.filter((blockContent) => {
                return blockContent.style === "h2";
            });
            headingBlocks = headingBlocks.concat(h2inColorCards);
        }
    }

    return headingBlocks;
};

export const SectionHeadingNav = ({ blocks, title }) => {
    const headingBlocks = getHeadingBlocks(blocks);

    if (!headingBlocks.length) return null;

    return (
        <Container className="section-heading-nav">
            <Heading as="span" level="h5" levelStyle="h5">
                Chapters
            </Heading>
            <ul>
                <SanityBlockContent
                    blocks={headingBlocks}
                    serializers={serializers}
                />
            </ul>
        </Container>
    );
};
