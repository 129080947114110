import { BlogSubscriptionForm } from "../components/Blog/BlogSubscriptionForm";
import { graphql } from "gatsby";
import React, { useRef } from "react";
import { Head } from "../components/Head";
import styled, { ThemeProvider } from "styled-components";
import {
    MetaTag,
    getBlogPostDefinition,
    StructuralJSONBlock,
} from "../components/HeadSEO";
import { BlogFooterCTA } from "../components/BlogArticle/BlogCTA";
import { usePageTheme } from "../styles/pageThemes";
import { BlogHeader } from "../components/BlogArticle/BlogHeader";
import { getSrc } from "gatsby-plugin-image";
import { BlogBody } from "../components/BlogArticle/BlogBody";
import { ContentGroups } from "../constants/contentGroups";
import { SmoothScroll } from "../components/SmoothScroll";

const BlogPostLayout = styled.article`
    width: 100%;
    max-width: 1400px;

    padding: 36px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${(p) => p.theme.media("md")} {
        padding: 64px 48px;

        .featured-image-wrapper {
            width: calc(100% + 24px);
            transform: translateX(-12px);
        }
    }

    ${(p) => p.theme.media("xl")} {
        gap: 32px;

        padding: 96px 72px;

        .featured-image-wrapper {
            width: calc(100% + 72px);
            transform: translateX(-36px);
        }
    }

    ${(p) => p.theme.media("xxl")} {
        gap: 64px;

        padding: 120px 128px;

        .featured-image-wrapper {
            width: calc(100% + 128px);
            transform: translateX(-64px);
        }
    }
`;

const BlogPost = ({
    data,
}: {
    data: { sanityBlogPost: GatsbyTypes.SanityBlogPost };
}) => {
    const {
        _rawBody,
        canonicalUrl,
        description = "",
        featuredImage,
        publishedAt,
        linkedProduct,
        tags,
        funnelStage,
        title = "",
        pageTheme,
    } = data.sanityBlogPost;

    const articleBodyRef = useRef(null);

    const newTheme = usePageTheme(pageTheme);

    // for now let the blog categories serve as the keywords then come back and revisit the schema.
    const keywords = tags
        ? tags
              .filter((tag) => {
                  // remove empty or internal tags
                  return tag && !/in-app|highlight_all/.test(tag.title);
              })
              // grab the tag titles
              .map((tag) => tag.title)
        : [];

    const gatsbyImgData = featuredImage?.img?.asset?.gatsbyImageData;
    const featuredImageSrc = gatsbyImgData ? getSrc(gatsbyImgData) : null;

    return (
        <ThemeProvider theme={newTheme}>
            <Head
                title={title}
                contentGroup={ContentGroups[funnelStage]}
                description={
                    description ||
                    "Follow our blog for more agile resources, including strategies, how-to guides and product updates."
                }
                canonicalUrl={canonicalUrl}
                image={featuredImageSrc}
                onCustomizeStructuralJSON={(
                    defaultPageSEO: StructuralJSONBlock[], // default JSON+LD from easyagile.com
                    metaInfo: MetaTag[] // default <meta> tags fro easyagile.com
                ): StructuralJSONBlock[] => {
                    // the return results here are the entire LD+JSON contents
                    // include the base, plus any new ones you want to add
                    return [
                        ...defaultPageSEO,
                        getBlogPostDefinition(
                            publishedAt,
                            _rawBody,
                            keywords,
                            metaInfo
                        ),
                    ];
                }}
            />
            <SmoothScroll />
            <BlogPostLayout ref={articleBodyRef}>
                <BlogHeader blogPost={data.sanityBlogPost} />
                <BlogBody blogPost={data.sanityBlogPost} />
            </BlogPostLayout>

            <BlogFooterCTA
                product={linkedProduct}
                articleBody={articleBodyRef}
            />

            <BlogSubscriptionForm />
        </ThemeProvider>
    );
};

export default BlogPost;

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        sanityBlogPost(id: { eq: $id }) {
            _rawBody(resolveReferences: { maxDepth: 5 })
            funnelStage
            author {
                ... on SanityCollaborator {
                    id
                    image {
                        asset {
                            gatsbyImageData(
                                width: 64
                                layout: FIXED
                                placeholder: NONE
                            )
                            altText
                        }
                    }
                    linkedIn
                    name
                    title
                    company
                    twitter
                }
                ... on SanityAuthor {
                    id
                    image {
                        asset {
                            gatsbyImageData(
                                width: 64
                                layout: FIXED
                                placeholder: NONE
                            )
                            altText
                        }
                    }
                    linkedIn
                    name
                    title
                    twitter
                }
            }
            coAuthor {
                ... on SanityCollaborator {
                    id
                    image {
                        asset {
                            gatsbyImageData(
                                width: 64
                                layout: FIXED
                                placeholder: NONE
                            )
                            altText
                        }
                    }
                    linkedIn
                    name
                    title
                    company
                    twitter
                }
                ... on SanityAuthor {
                    id
                    image {
                        asset {
                            gatsbyImageData(
                                width: 64
                                layout: FIXED
                                placeholder: NONE
                            )
                            altText
                        }
                    }
                    linkedIn
                    name
                    title
                    twitter
                }
            }
            artist {
                ... on SanityCollaborator {
                    id
                    image {
                        asset {
                            gatsbyImageData(
                                width: 64
                                layout: FIXED
                                placeholder: NONE
                            )
                            altText
                        }
                    }
                    linkedIn
                    name
                    title
                    company
                    twitter
                }
                ... on SanityAuthor {
                    id
                    image {
                        asset {
                            gatsbyImageData(
                                width: 64
                                layout: FIXED
                                placeholder: NONE
                            )
                            altText
                        }
                    }
                    linkedIn
                    name
                    title
                    twitter
                }
            }
            canonicalUrl
            description
            featuredImage {
                img {
                    asset {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            width: 880
                            placeholder: NONE
                        )
                        metadata {
                            dimensions {
                                width
                            }
                        }
                    }
                }
                alt
            }
            publishedAt(formatString: "DD, MMM YYYY")
            relatedArticles {
                ...SanityRelatedArticlesFields
            }
            linkedProduct {
                title
                atlassianProductId
                icon {
                    asset {
                        _id
                        gatsbyImageData(placeholder: NONE)
                        altText
                    }
                }
                productPage {
                    title
                    slug {
                        current
                    }
                }
            }
            category {
                name
            }
            tags {
                title
            }
            pageTheme
            title
        }
    }
`;
