import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Hyperlink } from "../Link/Hyperlink";
import { GetTrialUrl } from "../../constants/urls";
import Button from "../Button";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import { bold14Type } from "../../styles/typography";

const StyledLink = styled(Hyperlink)`
    text-decoration: underline;
`;

const StickyFooter = styled.div<{ show: boolean }>`
    background: white;
    display: flex;
    position: sticky;
    z-index: 2;
    width: 100vw;
    height: 64px;
    bottom: 0px;
    align-items: center;
    justify-content: center;

    opacity: ${(p) => (p.show ? "1" : "0")};
    transition: opacity 250ms ease-in-out;

    border-top: 1px solid ${(p) => p.theme.color.divider.subtle};
`;

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: clamp(0px, 100%, 800px);
    justify-content: center;
    height: 100%;
    padding: 0px 15px;
`;

const ProductInfo = styled.div`
    display: flex;
    flex-grow: 1;
    height: 100%;

    align-items: center;
    gap: 12px;
`;

const WrapperLink = styled(Hyperlink)`
    height: 100%;

    display: flex;

    align-items: center;

    ${(p) => p.theme.media("sm")} {
        width: 100%;
    }
`;

const Name = styled.div`
    ${bold14Type}
`;

const ButtonWrapper = styled.div`
    display: none;

    ${(p) => p.theme.media("sm")} {
        display: flex;
        align-items: center;
    }
`;

const IconWrapper = styled.div`
    height: calc(100% - 12px);
    aspect-ratio: 1 / 1;
`;

export const BlogHeaderCTA = ({ product }) => {
    if (!product) return null;

    return (
        <div>
            <StyledLink href={`/products/${product.productPage.slug.current}`}>
                <span>Learn more</span>
            </StyledLink>{" "}
            about Easy Agile's {product.title} for Jira!
        </div>
    );
};

export const BlogFooterCTA = ({ product, articleBody }) => {
    const [showFooter, setShowFooter] = useState(false);

    useEffect(() => {
        const listener = () => {
            if (showFooter) return;

            const cutoff =
                (articleBody.current.offsetTop +
                    articleBody.current.offsetHeight) /
                2;
            const position =
                document.documentElement.scrollTop + window.innerHeight;

            if (position >= cutoff) setShowFooter(true);
        };
        window.addEventListener("scroll", listener);

        return () => window.removeEventListener("scroll", listener);
    }, [articleBody]);

    const icon = useMemo(() => {
        return {
            image: getGatsbyImageData(
                product?.icon,
                { aspectRatio: 1 },
                {
                    dataset: process.env.GATSBY_SANITY_DATASET,
                    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
                }
            ),
            alt: product?.icon?.asset?.altText,
        };
    }, [product?.icon]);

    if (!product) return null;

    return (
        <StickyFooter show={showFooter}>
            <FooterWrapper>
                <WrapperLink
                    href={`/products/${product.productPage.slug.current}`}
                >
                    <>
                        <ProductInfo>
                            <IconWrapper>
                                <GatsbyImage
                                    image={icon.image}
                                    alt={icon.alt || product.productPage.title}
                                />
                            </IconWrapper>
                            <Name>{product.productPage.title}</Name>
                        </ProductInfo>
                        <ButtonWrapper>
                            <Button
                                href={`/products/${product.productPage.slug.current}`}
                                variation="redBackgroundDarkText"
                            >
                                Find out more
                            </Button>
                        </ButtonWrapper>
                    </>
                </WrapperLink>
            </FooterWrapper>
        </StickyFooter>
    );
};
